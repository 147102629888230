
import React, { useState, useContext } from "react";
import UserPrefsContext from "../../../context//UserPrefs/UserPrefsContext";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "chartjs-plugin-datalabels";
import { Scatter } from 'react-chartjs-2';
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import filterEVs from "../../../functions/vehicle/Filter/filterEVs";
import { FormatCarName, FormatAsThousands } from "../../../utils/Helpers/Format"
import getPaymentDetails from "../../../functions/vehicle/getPaymentDetails"
import electricDot from "../../assets/images/dots/electric.png"
import pluginDot from "../../assets/images/dots/plugin.png";

const CostVsRangeGraph = ({ electricVehicles, toggleRange }) => {

    const userPrefs = useContext(UserPrefsContext);
    const [renderTooltip, setRenderTooltip] = useState(false)
    const [renderEvs, setRenderEvs] = useState([])
    const [topPos, setTopPos] = useState('0px')
    const [leftPos, setLeftPos] = useState("0px")

    if (!electricVehicles) return <LoadingSpinner />;

    const filteredAndSortedElectricVehicles = filterEVs(electricVehicles, {
        vehicleFormFactorFilters: userPrefs.get("vehicleFormFactorFilters"),
        vehicleFuelTypeFilters: userPrefs.get("vehicleFuelTypeFilters"),
        vehicleMakeFilter: userPrefs.get("vehicleMakeFilter"),
        vehicleAgeFilter: userPrefs.get("vehicleAgeFilter")
    });

    if (!filteredAndSortedElectricVehicles.length) {
        return (
            <div className="text-center">
                <p className="h4">No vehicles found</p>
            </div>
        );
    }

    const milesDrivenAnnually = userPrefs.get("milesDrivenAnnually");

    const dataCoordiantes = filteredAndSortedElectricVehicles.map(ev => {
        let paymentDetails = getPaymentDetails(
            ev,
            userPrefs.get("monthsOfOwnership"),
            userPrefs.get("interestRateAsBasisPoints"),
            milesDrivenAnnually,
            userPrefs.get("salesTax")
        )

        if (toggleRange) {
            return { x: ev.total_range, y: paymentDetails.afterIncentives }
        } else {
            return { x: ev.electric_range, y: paymentDetails.afterIncentives }
        }
    })

    const dataImages = filteredAndSortedElectricVehicles.map(ev => {
        let evImage = new Image()
        evImage.src = ev.fuel === 'BEV' ? electricDot : pluginDot;        
        return evImage
    })

    const data = {
        labels: ['Scatter'],
        datasets: [
            {
                label: 'EVs',
                showLine: false,
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.4)',
                pointBackgroundColor: 'yellow',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                pointStyle: dataImages,
                data: dataCoordiantes,
                datalabels: {
                    display: false
                }
            }
        ]
    };

    const showTooltip = (tooltip) => {
        if (tooltip.opacity === 0) {
            // Leaving the tooltip
            setRenderTooltip(false)

        } else {
            // ENTER THE TOOPTIP
            let highlightedEvs = tooltip.dataPoints.map(dataPoint => {
                return filteredAndSortedElectricVehicles[dataPoint.index]
            })

            setTopPos( - 168 - 16 + tooltip.caretY + "px");
            setLeftPos( - 229 + 8 + tooltip.caretX + "px");

            setRenderEvs(highlightedEvs)
            setRenderTooltip(true)
        }
    }

    const renderTooltips = () => {
        return renderEvs.map(ev => {
            let paymentDetails = getPaymentDetails(
                ev,
                userPrefs.get("monthsOfOwnership"),
                userPrefs.get("interestRateAsBasisPoints"),
                milesDrivenAnnually,
                userPrefs.get("salesTax")
            )
            return (
              <Link
                to={`/vehicles/${ev.handle}`}
                className="tooltipEvName"
                key={ev.handle}
              >
                <div
                  style={{ top: topPos, left: leftPos }}
                  key={ev.electric_vehicle_id}
                  className="row renderToolTip"
                >
                  <img
                    style={{ width: "187px", height: "110px", margin: "0" }}
                    src={ev.images[0].url_full}
                    alt={ev.display_name}
                  />
                  <div className="tooltipTextContainer">
                    <div className="tooltipDescription">
                      <h5>{FormatCarName(ev)}</h5>
                      <p>
                        From at $
                        {FormatAsThousands(paymentDetails.afterIncentives)}{" "}
                        after incentives
                      </p>
                      <p>Electric Range: {ev.electric_range} Miles</p>
                    </div>
                  </div>
                </div>
              </Link>
            );
        })
    }

    return (
        <div className="cost-vs-range-graph">
            <Scatter
                data={data}
                options={{
                    scales: {
                        y: {
                            type: 'linear',
                            position: 'bottom',
                            scaleLabel: {
                                display: true,
                                labelString: "MSRP After Incentives",
                                fontSize: 16,
                                fontColor: "#73767B"
                            },
                            ticks: {
                                //   max: 160000,
                                //   min: 0,
                                // Include a dollar sign in the ticks
                                callback: function (value, index, values) {
                                    var formatter = new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 0, /* this might not be necessary */
                                    });
                                    return formatter.format(value).replace(',000','k');
                                },
                                fontSize: 16
                            }
                        },
                        x: {
                            // max: max_x,
                            min: 0,
                            scaleLabel: {
                                display: false,
                                labelString: toggleRange ? "Total Range (miles)" : "Electric Range (miles)",
                                fontSize: 20,
                                fontColor: "#2b2b2b"
                            },
                            ticks: {
                                fontSize: 16
                            }
                        }
                    },
                    tooltips: {
                        enabled: false,
                        custom: showTooltip
                    },
                    // Displays a Legend at the top of the graph
                    legend: {
                        display: false
                    }
                }}
            />

            {renderTooltip ? renderTooltips() : ""}
        </div>
    )
}

export default CostVsRangeGraph

CostVsRangeGraph.propTypes = {
    electricVehicles: PropTypes.array
}

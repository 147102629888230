import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import MonthlyFuelCostChart from "../CostsCharts/MonthlyFuelCostChart";
import FuelRangeChart from "../CostsCharts/FuelRangeChart";
import CostToOwnComparison from "../CostsCharts/CostToOwnComparison";
import CostOfOwnershipOptions from "./../CostOfOwnershipOptions/CostOfOwnershipOptions";
import { FormatCarName } from "./../../utils/Helpers/Format";
import ShowHideButton from "../ShowHideButton/ShowHideButton";
import fetchWrapper from "../../utils/fetch/fetchWrapper";
import CalcPanels from "./CalcPanels/CalcPanels";
import RenderAssumption from "../RenderAssumption/RenderAssumption";
import {
     SALES_TAX,
     GASOLINE_PRICE_IN_DOLLARS_PER_GAL,
     DOWN_PAYMENT,
     ELECTRICITY_RATE_IN_DOLLARS_PER_KWH,
} from "../../client_customizations/data/assumptions/ASSUMPTIONS";
import { FormattedMessage, useIntl } from "react-intl";
import BreakevenChart from "../CostsCharts/BreakevenChart";

const CostOfOwnership = ({ cars, title, comparison, usedEv }) => {
     const intl = useIntl();
     const userPrefs = useContext(UserPrefsContext);
     const [isLoading, setIsLoading] = useState(true);
     const [insuranceData, setInsuranceData] = useState(null);
     const [maintenanceData, setMaintenanceData] = useState(null);

     useEffect(() => {
          const loadInsuranceData = async (vehicle_handle) => {
               const params = {
                    postcode: userPrefs.get("zipcode"),
               };
               let url = new URL(`${process.env.REACT_APP_API_HOST}/vehicles/${vehicle_handle}/insurance`);
               let searchParams = new URLSearchParams(params);
               url.search = searchParams;
               try {
                 const insuranceData = await fetchWrapper(url.toString(), {
                   method: "GET",
                 });

                 setInsuranceData(insuranceData.yearly_insurances);
                 setIsLoading(false);
               } catch (error) {
                 console.log("Failed to load insurance data.");
                 console.log(error);
                 setIsLoading(false);
               }
          };

          const loadMaintenanceData = async (cars) => {
               const params = {
                    annualmiles: userPrefs.get("milesDrivenAnnually"),
                    postcode: userPrefs.get("zipcode"),
               };

               let maintenanceData = [];
               for (let i = 0; i < cars.length; i++) {
                    let vehicle_handle = cars[i].handle;
                    let url = new URL(
                         `${process.env.REACT_APP_API_HOST}/vehicles/${vehicle_handle}/maintenance`
                    );
                    let searchParams = new URLSearchParams(params);
                    url.search = searchParams;

                    try {
                      const resultData = await fetchWrapper(url.toString(), {
                        method: "GET",
                      });

                      maintenanceData.push({
                        handle: vehicle_handle,
                        maintenance: resultData.maintenance.costs,
                      });
                    } catch (error) {
                      console.log("Failed to load maintenance data.");
                      console.log(error);
                      setIsLoading(false);
                    }
               }

               setMaintenanceData(maintenanceData);
               setIsLoading(false);
          };

          if (cars && cars.length > 0) {
               loadInsuranceData(cars[0].handle);
               loadMaintenanceData(cars);
          }
     }, [cars, userPrefs]);

     if (!cars || cars.length === 0) return null;

     const car = cars[0];
     const equivalentGasVehicle = cars[1];

     SALES_TAX.title = intl.formatMessage({ id: "assumption.salesTax", defaultMessage: "Sales Tax" });
     SALES_TAX.value = userPrefs.get("salesTax");
     SALES_TAX.valueAsFormatted = (userPrefs.get("salesTax") * 100).toFixed(2) + "%";
     GASOLINE_PRICE_IN_DOLLARS_PER_GAL.title = intl.formatMessage({
          id: "assumption.gasolinePrice",
          defaultMessage: "Price of Gasoline",
     });
     DOWN_PAYMENT.title = intl.formatMessage({ id: "assumption.downPayment", defaultMessage: "Down Payment" });
     DOWN_PAYMENT.valueAsFormatted = intl.formatMessage({
          id: "assumption.downPaymentValue",
          defaultMessage: "10% of MSRP + Tax",
     });
     ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.title = intl.formatMessage({
          id: "assumption.electricityRate",
          defaultMessage: "Electricity Rate",
     });

     return (
          <section className="container" id="CostOfOwnership">
               <div className="CostOfOwnership">
                    <div className="row">
                         <div className={`col-sm-12 ${comparison ? `` : `text-center`}`}>
                              <p className={usedEv ? `h1 p-3` : `h1`}>
                                   {title || (
                                        <FormattedMessage
                                             id="ev.compareGasVehicle"
                                             defaultMessage="Compare the {car} to a similar gas vehicle, the {gasCar}"
                                             description="Description of Colton Recharged"
                                             values={{
                                                  car: FormatCarName(car),
                                                  gasCar: FormatCarName(equivalentGasVehicle),
                                             }}
                                        />
                                   )}
                              </p>
                         </div>
                    </div>
               </div>
               <div className="row">
                    <div className="col-md-4 col-lg-3" style={{ textAlign: "left" }}>
                         <CostOfOwnershipOptions cars={cars} usedEv={usedEv} />
                         <br />
                         <div className="input-well d-none d-md-block">
                              <p className="RenderItemTitle" style={{ marginBottom: 10 }}>
                                   <FormattedMessage
                                        id="graph.assumptions"
                                        defaultMessage="Assumptions"
                                        description="Assumptions"
                                   />
                              </p>

                              <RenderAssumption assumption={SALES_TAX} />
                              {/* <RenderAssumption assumption={GASOLINE_PRICE_IN_DOLLARS_PER_GAL} /> */}
                              {userPrefs.get("purchaseMethod") === "cash" || usedEv ? null : (
                                   <RenderAssumption assumption={DOWN_PAYMENT} />
                              )}
                              <RenderAssumption assumption={ELECTRICITY_RATE_IN_DOLLARS_PER_KWH} />
                              <p className="legal-disclaimer">
                                   <small>
                                        <FormattedMessage
                                             id="ev.electricityRateCalc"
                                             defaultMessage="Electricity rate is calculated as the DTE off-peak Time of Day
                Rate 11 a.m. - 7 p.m. (D1.2). To learn more, please click {website}"
                                             description="Assumptions - Electricity Rate"
                                             values={{
                                                  website: (
                                                       <a href="https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home/service-request/residential/electric/pev/pev-res-rate-plans">
                                                            here
                                                       </a>
                                                  ),
                                             }}
                                        />
                                   </small>
                              </p>
                         </div>
                    </div>
                    <div
                         id="costs-chart-areas"
                         aria-live="polite"
                         aria-atomic="true"
                         role="region"
                         style={{ position: "absolute", top: "-9999px" }}
                    >
                         <span>Results have been updated as of {new Date().toLocaleString()}.</span>
                    </div>
                    <div className="col-md-8 col-lg-9">
                         {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED ? (
                              <MonthlyFuelCostChart cars={cars} comparison={comparison} />
                         ) : null}
                         <br />
                         {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_OWN_COMPARISON &&
                         !isLoading &&
                         insuranceData ? (
                              <CostToOwnComparison
                                   cars={cars}
                                   hasExtraPadding
                                   comparison={comparison}
                                   insuranceData={insuranceData}
                                   maintenanceData={maintenanceData}
                              />
                         ) : null}
                         <br />
                         {process.env.REACT_APP_PAGES_HOME_GRAPH_BUDGT_ENABLED ? <FuelRangeChart car={car} /> : null}
                         <br />
                         {process.env.REACT_APP_PAGES_BREAKEVEN_GRAPH ? <BreakevenChart cars={cars} /> : null}
                         {!comparison && (
                              <p className="text-left">
                                   <FormattedMessage
                                        id="ev.carDetails.compareText"
                                        defaultMessage="We selected the {gasVehicle} based on its
                proximity to the {vehicle} based on brand, size, and price."
                                        description="Compare Vehicle Text"
                                        values={{
                                             gasVehicle: FormatCarName(equivalentGasVehicle),
                                             vehicle: FormatCarName(car),
                                        }}
                                   />
                              </p>
                         )}
                    </div>
                    <div className="col-sm-12 text-center">
                         <br />
                         <ShowHideButton
                              buttonText={` ${intl.formatMessage({
                                   id: "sources",
                                   defaultMessage: "Sources",
                              })}`}
                              displayAlertOnlyOnMobile
                         >
                              <div className="input-well">
                                   <CalcPanels cars={cars} />
                              </div>
                         </ShowHideButton>
                    </div>
               </div>
          </section>
     );
};

export default CostOfOwnership;

CostOfOwnership.propTypes = {
     cars: PropTypes.array,
};
